import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LINKS } from 'constants/links'
import { useTranslation } from 'next-i18next'
import { LoginForm } from './form/LoginForm'

export function Login() {
  const { t } = useTranslation(['auth'])

  return (
    <>
      <Box marginBottom={3}>
        <LoginForm />
      </Box>

      <Stack gap={1} direction="row" justifyContent="space-between">
        <Typography variant="body2">
          <Link href={LINKS.register} underline="hover">
            {t('auth:login.registerLink')}
          </Link>
        </Typography>
        <Typography variant="body2">
          <Link href={LINKS.resetPassword} underline="hover">
            {t('auth:login.resetPasswordLink')}
          </Link>
        </Typography>
      </Stack>
    </>
  )
}

import { useDefaultZodErrorMap } from 'utils/validations'
import { infer as zodInfer, object, string } from 'zod'

export function useLoginFormValidationSchema() {
  const schema = object({
    email: string().email(),
    password: string().min(1)
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export type LoginFormValues = zodInfer<
  ReturnType<typeof useLoginFormValidationSchema>['schema']
>

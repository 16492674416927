import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { Input } from 'components/form/Input'
import { DEFAULT_FORM_VALIDATION_MODE } from 'constants/forms'
import { LINKS } from 'constants/links'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { FORM_GAP } from 'sections/auth/Auth.utils'
import {
  LoginFormValues,
  useLoginFormValidationSchema
} from './LoginForm.utils'

export function LoginForm() {
  const { t } = useTranslation(['auth'])
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const { schema, errorMap } = useLoginFormValidationSchema()
  const form = useForm<LoginFormValues>({
    resolver: zodResolver(schema, { errorMap }),
    mode: DEFAULT_FORM_VALIDATION_MODE,
    defaultValues: {
      email: '',
      password: ''
    }
  })

  async function onSubmit(values: LoginFormValues) {
    setIsLoading(true)
    await signIn('credentials', { ...values, redirect: false }).then(data => {
      setIsLoading(false)
      if (data?.error) {
        toast.error(t('auth:login.error'))
      } else {
        router.push(LINKS.app)
      }
    })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack gap={FORM_GAP}>
          <Controller
            name="email"
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Input
                name={name}
                value={value || null}
                onChange={onChange}
                onBlur={onBlur}
                type="email"
                label={t('auth:fields.email')}
                autoComplete="email"
                required
                sx={{
                  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                      '-webkit-box-shadow':
                        '0 0 0 100px #fffbf7 inset !important'
                    }
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Input
                name={name}
                value={value || null}
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                label={t('auth:fields.password')}
                autoComplete="password"
                required
                sx={{
                  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                      '-webkit-box-shadow':
                        '0 0 0 100px #fffbf7 inset !important'
                    }
                }}
              />
            )}
          />

          <Button
            variant="contained"
            size="large"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={16} />
            ) : (
              t('auth:login.submit')
            )}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}

import { PageSession } from 'components/PageSession'
import { LayoutAuth } from 'components/layout/auth/LayoutAuth'
import { SeoTitle } from 'components/seo/SeoTitle'
import { DEFAULT_REVALIDATE_TIME } from 'constants/hydrate'
import { FALLBACK_LOCALE } from 'constants/locale'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect } from 'react'
import { Login } from 'sections/auth/login/Login'
import { localStorageClient } from 'utils/dom'
import { usePageTranslation } from 'utils/intl'

function HomePage() {
  const t = usePageTranslation(['common', 'auth'])

  useEffect(() => {
    localStorageClient.removeItem('displayedAccounts')
    localStorageClient.removeItem('calendarParams')
    localStorageClient.removeItem('calendarFilters')
  }, [])

  return (
    <PageSession allowedGroup="guest" hideLoadingTitle>
      <>
        <SeoTitle title={t('auth:login.title')} />

        <LayoutAuth
          title={t('auth:login.title')}
          subtitle={t('auth:login.subtitle')}
          displayTitleLogo
        >
          <Login />
        </LayoutAuth>
      </>
    </PageSession>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? FALLBACK_LOCALE, [
        'auth',
        'common'
      ]))
    },
    revalidate: DEFAULT_REVALIDATE_TIME
  }
}

export default HomePage
